import axios, { type AxiosResponse } from 'axios'

const element = document.querySelector('meta[name=csrf-token]')
const axiosInstance = axios.create({headers: {'X-CSRF-Token': element?.getAttribute('content') ?? ''}})

const api = {
	delete<T>(url: string, body?: {}): Promise<AxiosResponse<T>> {
		if (body) {
			return axiosInstance.delete(url, { data: body })
		} else {
			return axiosInstance.delete(url)
		}
	},

	get<T>(url: string, params?: any): Promise<AxiosResponse<T>> {
		return axiosInstance.get(url, { params })
	},

	post<T, U = {}>(url: string, body?: U, config?: {}): Promise<AxiosResponse<T>> {
		return axiosInstance.post(url, body, config)
	},

	put<T, U = {}>(url: string, body: U): Promise<AxiosResponse<T>> {
		return axiosInstance.put(url, body)
	},
}

export default api

export type ApiClient = typeof api

export interface Header {
	headers: {
		Accept?: 'application/json',
		'X-CSRF-Token'?: Element | string,
	},
}

export interface ResponseData extends AxiosResponse {
	errors?: string[]
	message?: string
}
